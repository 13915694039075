export default {
  title: {
    lineOne: 'Resources',
    lineTwo: 'for borrowers',
  },

  forBorrowersLinks: [
    {
      text: 'Your Dashboard',
      linkTo: '/for-borrowers',
    },
    {
      text: 'Our Loan Offerings',
      linkTo: '/faq?question=6#i-want-to-learn',
    },
    {
      text: 'Offer Ready ',
      linkTo: '/offer-ready',
    },
    {
      text: 'FAQ',
      linkTo: '/faq',
    },
    {
      text: 'Contact Us',
      linkTo: '/faq#contactus',
    },
  ],

  buttons: {
    buy: 'Apply for a home loan',
    refinance: 'Apply for refinancing',
  },
};
