import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import SecondaryButton from 'components/commons/SecondaryButton/SecondaryButton';
import sendGaEvent from 'src/utils/gtag';
import lang from 'src/data/lang/header.lang';
import ApplyNowButton from '../../ApplyNowButton/ApplyNowButton';
import BorrowersDropdown from '../BorrowersDropdown/BorrowersDropdown';
import styles from './links.module.scss';

const { links, pillButtons, agentLogin, agentLoginLinkText } = lang;
const Links = ({ open }: { open: boolean }) => {
  const [displayDropdown, setDisplayDropdown] = useState(false);

  const linksRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleNavDisplay = () => {
    return open ? styles.showNav : styles.hideNav;
  };

  const handleDropdownDisplay = () => {
    return displayDropdown ? styles.active : styles.inactive;
  };

  useEffect(() => {
    if (open) {
      setDisplayDropdown(true);
    } else {
      setDisplayDropdown(false);
    }
  }, [open]);

  return (
    <nav
      className={`${styles.headerNavWrapper} ${
        displayDropdown ? styles.headerNavWrapperActive : ''
      } ${handleNavDisplay()}`}
      ref={linksRef}
    >
      <div className={styles.headerNavContainer}>
        <div className={styles.headerLinks}>
          {links.map(link => {
            const gaEvent = () => {
              switch (link.text) {
                case 'FAQ':
                  return 'clickedFaq';
                case 'For Agents':
                  return 'clickedForAgents';
                default:
                  throw new Error('Invalid event');
              }
            };

            return link.text === 'For Borrowers' ? (
              <div key={link.id} className={styles.linkMegamenu}>
                <button
                  className={`${styles.headerLink} ${styles.megamenuButton}`}
                  type="button"
                  data-testid={link.href}
                  onClick={() => {
                    sendGaEvent(
                      !displayDropdown
                        ? 'clickedForBorrowersOpen'
                        : 'clickedForBorrowersClose',
                      'shared'
                    );
                    setDisplayDropdown(!displayDropdown);
                  }}
                >
                  {link.text}
                </button>
                <div
                  className={`${styles.dropdown} ${handleDropdownDisplay()}`}
                  ref={dropdownRef}
                >
                  <BorrowersDropdown />
                </div>
              </div>
            ) : (
              <Link key={link.id} href={link.href} data-testid={link.href}>
                <a
                  href="/"
                  className={styles.headerLink}
                  onClick={() => sendGaEvent(gaEvent(), 'shared')}
                  // activeClassName={styles.active}
                >
                  {link.text}
                </a>
              </Link>
            );
          })}
          <div className={styles.agentLogin}>
            <a
              className={styles.agentLoginLink}
              href={agentLogin}
              onClick={() => sendGaEvent('clickedKwLogin', 'shared')}
              data-testid="agentLoginButton"
            >
              {agentLoginLinkText}
            </a>
          </div>
        </div>
        <div className={styles.buttons}>
          {pillButtons.map((pill, index) =>
            index === 0 ? (
              <SecondaryButton
                specialWidth="small"
                key={pill.id}
                linkTo={pill.linkTo}
                testID={pill.linkTo}
                handleOnClick={() =>
                  sendGaEvent('clickedPhoneNumber', 'shared')
                }
              >
                {pill.text}
              </SecondaryButton>
            ) : (
              <ApplyNowButton
                key={pill.id}
                gaEvent="clickedApplyNow"
                gaPage="shared"
                specialWidth="small"
                testID={pill.linkTo}
              >
                {pill.text}
              </ApplyNowButton>
            )
          )}
        </div>
      </div>
    </nav>
  );
};

export default Links;
