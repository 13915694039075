import BuyIconDefault from 'components/commons/svg/BuyIconDefault';
import RefinanceIconDefault from 'components/commons/svg/RefincanceIconDefault';
import Link from 'next/link';
import NewOfferBanner from 'public/img/for-agents/NewOfferBanner.svg';
import React from 'react';
import { GaEvent, Origin } from 'src/common/types';
import { useFormMachine } from 'src/context/FormMachineProvider';
import lang from 'src/data/lang/borrowers-dropdown.lang';
import sendGaEvent from 'src/utils/gtag';
import styles from './borrowers-dropdown.module.scss';

const BorrowersDropdown = () => {
  const [state, send] = useFormMachine();

  const {
    title: { lineOne, lineTwo },
    forBorrowersLinks,
    buttons: { buy, refinance },
  } = lang;

  const handleBeginFlow = async (
    gaEvent: GaEvent,
    type: 'buy' | 'refinance',
    origin: Origin
  ) => {
    sendGaEvent(gaEvent, 'shared');
    if (type !== state.context.answers.buyOrRefinance) {
      send('CLEAR_ANSWERS');
    }
    send({
      type: `SET_ORIGIN_${type.toUpperCase() as 'BUY' | 'REFINANCE'}`,
      origin,
      property: 'buyOrRefinance',
      value: type,
    });
  };
  return (
    <div className={styles.container}>
      <div className={styles.linksContainer}>
        <div className={styles.title}>
          <p>{lineOne}</p>
          <p>{lineTwo}</p>
        </div>
        <div className={styles.links}>
          {forBorrowersLinks.map(link => {
            const gaEvent = () => {
              switch (link.text) {
                case 'Your Dashboard':
                  return 'clickedYourDashboard';
                case 'Our Loan Offerings':
                  return 'clickedOurLoan';
                case 'Offer Ready ':
                  return 'clickedOfferReady';
                case 'FAQ':
                  return 'clickedBorrowersFaq';
                case 'Contact Us':
                  return 'clickedContactUs';
                default:
                  throw new Error('unexpected switch case');
              }
            };
            if (link.text === 'Offer Ready ') {
              return (
                <Link href={link.linkTo} key={link.linkTo}>
                  <a
                    href="dummy"
                    onClick={() => sendGaEvent(gaEvent(), 'shared')}
                  >
                    <span className={styles.offerReadyLink}>
                      <img
                        src={NewOfferBanner}
                        alt="New offer banner"
                        className={styles.newBanner}
                      />
                      <span className={styles.offerReadyHighlight}>
                        {link.text}
                      </span>
                      &nbsp;Program
                    </span>
                  </a>
                </Link>
              );
            }

            return (
              <Link href={link.linkTo} key={link.linkTo}>
                <a
                  href="dummy"
                  onClick={() => sendGaEvent(gaEvent(), 'shared')}
                >
                  {link.text}
                </a>
              </Link>
            );
          })}
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <button
          type="button"
          className={styles.button}
          data-testid="applyForAHomeButton"
          data-cy="fbApplyLoan"
          onClick={() =>
            handleBeginFlow('clickedApplyForAHome', 'buy', Origin.BUY_DROPDOWN)
          }
          aria-label="Apply for a home loan"
        >
          <BuyIconDefault />
          <p>{buy}</p>
        </button>
        <button
          type="button"
          className={styles.button}
          data-testid="applyForRefinancingButton"
          data-cy="fbApplyRefi"
          onClick={() =>
            handleBeginFlow(
              'clickedApplyForRefinancing',
              'refinance',
              Origin.REFINANCE_DROPDOWN
            )
          }
          aria-label="Apply for refinancing"
        >
          <RefinanceIconDefault />
          <p>{refinance}</p>
        </button>
      </div>
    </div>
  );
};

export default BorrowersDropdown;
