import styles from './hamburger-menu.module.scss';

const HamburgerMenu = ({
  handleOnClick,
  isActive,
}: {
  handleOnClick: (active: boolean) => void;
  isActive: boolean;
}) => {
  return (
    <div
      className={`${styles.hamburger} ${
        isActive ? styles.hamburgerActive : ''
      }`}
    >
      <button
        className={styles.navigationTrigger}
        type="button"
        onClick={() => handleOnClick(!isActive)}
        aria-label="Menu"
      >
        <span />
        <span />
      </button>
    </div>
  );
};

export default HamburgerMenu;
