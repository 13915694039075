import React from 'react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import styles from './layout.module.scss';

const Layout: React.FC = ({ children }) => {
  return (
    <div className={styles.layout}>
      <Header />
      <div className={styles.lpMain}>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
