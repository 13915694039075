const phoneNumber = '1-888-766-2678';

const links = [
  {
    id: 1,
    text: 'FAQ',
    href: '/faq',
  },
  { id: 2, text: 'For Borrowers', href: '/for-borrowers' },
  { id: 3, text: 'For Agents', href: '/for-agents' },
];

const agentLogin = 'https://portal.kellermortgage.com/';

const agentLoginLinkText = 'Agent Login';

const pillButtons = [
  {
    id: 1,
    text: phoneNumber,
    linkTo: `tel:${phoneNumber.replace('-', '')}`,
  },
  {
    id: 2,
    text: 'Apply Now!',
    linkTo: '/mortgage-application/get-agent-info',
  },
];

export default {
  links,
  agentLogin,
  agentLoginLinkText,
  pillButtons,
};
