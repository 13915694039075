import React, { useState } from 'react';
import Link from 'next/link';
import Logo from 'src/components/commons/svg/Logo';
import sendGaEvent from 'src/utils/gtag';
import styles from './header.module.scss';
import Links from './Links/Links';
import HamburgerMenu from '../HamburguerMenu/HamburguerMenu';

const Header = () => {
  const [navIsOpen, setNavIsOpen] = useState(false);

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.logo}>
          <Link href="/" aria-label="Home">
            <a
              href="/"
              onClick={() => {
                sendGaEvent('clickedLogoHeader', 'shared');
              }}
              aria-label="Home"
            >
              <Logo />
            </a>
          </Link>
          <p className={styles.nmls}>NMLS #140234</p>
        </div>

        <Links open={navIsOpen} />

        <HamburgerMenu isActive={navIsOpen} handleOnClick={setNavIsOpen} />
      </header>
    </div>
  );
};

export default Header;
